import React, { useEffect } from "react"
import { Link } from "gatsby"

import Prismic from 'prismic-javascript'
import PrismicDOM from 'prismic-dom'

import { Input, Button, Icon } from "antd"
import 'antd/dist/antd.css';

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ContactForm from '../components/ContactForm';

import { motion } from "framer-motion"

import "../styles.css"

const IndexPage = () => {
  useEffect(() => {
    var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
    i.src = "https://cdn.curator.io/published/4457a13b-6278-4640-8c08-a7d917c0b2b4.js";
    e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
  }, []);

  return (
    <Layout>
      <SEO title="Home" />

      <div className="slideshow">
        <div className="usp">
          <span className="usp-txt-home">Le bijou qui vous ressemble</span>
        </div>
      </div>
      <div className="border-gold-bottom"></div>


      <div className="gold-box-container">
        <div style={{ display: 'flex', justifyContent: 'center' }}>

          <div className="gold-box">
            <div className="gold-box-img-jewellery">
              <h4 style={{color: '#fff'}}>Bijouterie</h4>
              <Link to="/bijoux" style={{textDecoration: 'none', fontSize: '1.3em', letterSpacing: '1.2px'}}><div className="button-gold-box" style={{width: '80%', marginLeft: '10%'}}>Découvrir</div></Link>
            </div>
          </div>

          <div className="gold-box">
            <div className="gold-box-img-watches">
              <h4 style={{color: '#fff'}}>Horlogerie</h4>
              <Link to="/horlogerie" style={{textDecoration: 'none', fontSize: '1.3em', letterSpacing: '1.2px'}}><div className="button-gold-box" style={{width: '80%', marginLeft: '10%'}}>Découvrir</div></Link>
            </div>
          </div>

        </div>
      </div>
      <div className="margin-box"></div>

      <div className="gold-box-container-mobile">
        <Link to="/bijoux" style={{textDecoration: 'none'}}><div className="button-gold-box-jewellery">Bijouterie</div></Link>
        <Link to="/horlogerie" style={{textDecoration: 'none'}}><div className="button-gold-box-watches">Horlogerie</div></Link>

      </div>

      <h2 id="news_txt">News</h2>
      <a className="anchor2" id="news"></a>
      <section style={{minHeight: '800px'}}>
        <div id="curator-feed-default-layout"><a href="https://curator.io" target="_blank" className="crt-logo crt-tag">Powered
          by Curator.io</a></div>
      </section>

      <div className="separator"></div>
      <h3>« Luna’s » : Une Histoire De Famille</h3>
      <a className="anchor2 anchor3" id="presentation"></a>
      <section>
        <p className="p-2 p-font" style={{marginLeft: 0, marginRight: 0}}>
          L’entreprise familiale Luna’s a vu le jour en 1996. Après de longues années à travailler pour des marques joaillières et horlogères prestigieuses de la place genevoise, le joaillier Jean Luna et sa femme Rita ouvrirent leurs première boutique à Plan-Les-Ouates. Quelques années après, ce fut autour de la ville de Nyon puis celle de Morges d’accueillir le savoir-faire de la marque familiale.<br /><br />
          Un héritage transmit à leur fils Sebastian, qui à son tour, repris la boutique genevoise. 21 ans plus tard, la passion est encore plus forte et l’entreprise reprend un nouveau souffle. En effet, c’est un nouveau couple « Luna » Sebastian et sa femme Sophie, tous deux bijoutier-Joaillier.<br /><br />
          Ces deux amoureux reprennent l’entreprise familiale. Après avoir évolué séparément plusieurs années dans des ateliers spécialisés dans la haute-Joaillerie à Genève.<br /><br />
          Ils décidèrent alors d’allier leurs expériences et leurs savoirs-faire pour ainsi mettre à profit cette créativité dans ces boutiques.<br />
        </p>

          <p className="p-2 p-font p-citation" style={{marginLeft: 0, marginRight: 0}}>« J’ai eu la chance d’avoir appris le métier avec mon papa mais aussi dans un atelier où travaillait une famille de trois générations au même moment. Chacun avait sa spécialité; le sertissage, la bijouterie, l’orfèvrerie. Je suis naturellement très reconnaissant envers toutes ces personnes pour m’avoir transmis leurs savoirs d’une manière méthodique et efficace ».</p>
        <span className="nom-luna">Sebastian Luna</span>

        <p className="p-2 p-font" style={{paddingBottom:'0px', paddingTop: '60px'}}>Ainsi, les localisations des deux bijouteries se trouvent actuellement dans les régions natales des nouveaux propriétaires passionnés, à Genève et à Nyon. </p>

        <p className="p-2 p-font" style={{paddingTop:'0px'}}>La deuxième génération « Luna’s » s’est en plus prise d’affection pour l’horlogerie.</p>

        <p className="p-2 p-font p-citation">« Venez nous rendre visite pour créer vos désirs les plus fous ou restaurer vos bijoux remplis d’histoire ».</p>
        <span className="nom-luna">Sophie Luna</span>

      </section>

      <div className="separator" ></div>
      <h2>Contact</h2>
      <a className="anchor2" id="contact"></a>
      <section>

        <ContactForm product={null} />

        <div className="contact-container">

          <div className="contact-infos-container">
            <div />
            <div className="map-container">
              <div className="map">
                <iframe style={{width: '100%', border: '1px solid rgba(30,30,30,0.2)', minHeight: '320px'}} scrolling="no" marginHeight="0" marginWidth="0"
                        src="https://www.openstreetmap.org/export/embed.html?bbox=6.117544770240785%2C46.1675272430704%2C6.122801899909974%2C46.169841726039195&amp;layer=mapnik"></iframe>
                <small><a href="https://www.openstreetmap.org/#map=19/46.16868/6.12017">Afficher une carte plus
                  grande</a></small>
              </div>
              <div className="contact-infos-tabs">
                <h6>GENÈVE</h6>
                <ul className="contact-infos">
                  <li><strong>Bijouterie Luna's</strong></li>
                  <li className="contact-infos-tabs">Place des aviateurs, 4</li>
                  <li className="contact-infos-tabs">1228 Plan-les-Ouates</li>
                  <li className="contact-infos-tabs">Suisse</li>
                  <li className="contact-infos-tabs"><strong><a href="tel:022 743 00 84">022 743 00 84</a></strong></li>
                  <li className="contact-infos-tabs"><strong><a href="mailto:info@bijouterie-lunas.ch">info@bijouterie-lunas.ch</a></strong></li>
                </ul>
                <ul className="contact-infos">
                  <li><strong>Coordonnées Bancaires :</strong></li>
                  <li className="contact-infos-tabs">Banque UBS</li>
                  <li className="contact-infos-tabs" style={{fontSize: '1em'}}>IBAN: CH64 0024 0240 9757 2601 E</li>
                  <li className="contact-infos-tabs" style={{fontSize: '1em'}}>BIC / SWIFT : UBSWCHZH80A</li>
                </ul>
              </div>
              <div className="contact-infos-tabs">
                <ul className="contact-infos">
                  <li className="contact-infos-tabs" style={{fontSize: '1.4em', color: '#9c8958'}}><strong>Horaires :</strong></li>
                  <li className="contact-infos-tabs" style={{fontSize: '1em'}}><strong>Lundi - Mardi - Jeudi -Vendredi</strong></li>
                  <li className="contact-infos-tabs">9h30-12h / 14h45-18h</li>
                  <li className="contact-infos-tabs" style={{fontSize: '1em'}}><strong>Mercredi</strong></li>
                  <li className="contact-infos-tabs">9h30-12h</li>
                  <li className="contact-infos-tabs" style={{fontSize: '1em'}}><strong>Samedi</strong></li>
                  <li className="contact-infos-tabs">Sur rendez-vous</li>
                </ul>
              </div>
            </div>

            <div className="map-container">
              <div className="map">
                <iframe style={{width: '100%', border: '1px solid rgba(30,30,30,0.2)', minHeight: '320px'}} scrolling="no" marginHeight="0" marginWidth="0"
                        src="https://www.openstreetmap.org/export/embed.html?bbox=6.236009895801545%2C46.38084400804148%2C6.2412670254707345%2C46.38314949927569&amp;layer=mapnik"></iframe>
                <small><a href="https://www.openstreetmap.org/#map=19/46.38200/6.23864">Afficher une carte plus
                  grande</a></small>
              </div>
              <div className="contact-infos-tabs">
                <h6>NYON</h6>
                <ul className="contact-infos">
                  <li><strong>Bijouterie Luna's</strong></li>
                  <li className="contact-infos-tabs">Grand-Rue, 10</li>
                  <li className="contact-infos-tabs">1260 Nyon</li>
                  <li className="contact-infos-tabs">Suisse</li>
                  <li className="contact-infos-tabs"><strong><a href="tel:022 361 08 24">022 361 08 24</a></strong></li>
                  <li className="contact-infos-tabs"><strong><a href="mailto:info@bijouterie-lunas.ch">info@bijouterie-lunas.ch</a></strong></li>
                </ul>
                <ul className="contact-infos">
                  <li><strong>Coordonnées Bancaires :</strong></li>
                  <li className="contact-infos-tabs">Banque Cantonale Vaudoise  </li>
                  <li className="contact-infos-tabs" style={{fontSize: '1em'}}>IBAN : CH62 00767 000C 5420 0064</li>
                  <li className="contact-infos-tabs" style={{fontSize: '1em'}}>BIC / SWIFT : BCVLCH2LXXX</li>
                </ul>
              </div>
              <div className="contact-infos-tabs">
                <ul className="contact-infos">
                  <li className="contact-infos-tabs"style={{fontSize: '1.4em', color: '#9c8958'}}><strong>Horaires :</strong></li>

                  {/*<li className="contact-infos-tabs"style={{fontSize: '1em'}}><strong>Mardi - Jeudi - Vendredi</strong></li>
                  <li className="contact-infos-tabs">10h - 13h / 14h - 18h</li>
                  <li className="contact-infos-tabs"style={{fontSize: '1em'}}><strong>Mercredi</strong></li>
                  <li className="contact-infos-tabs">Fermé <span style={{fontSize: '0.7em'}}>/ Sur rdv uniquement</span></li>
                  <li className="contact-infos-tabs"style={{fontSize: '1em'}}><strong>Samedi</strong></li>
                  <li className="contact-infos-tabs">10h - 13h / 14h - 17h</li>*/}
                  <li className="contact-infos-tabs" style={{fontSize: '1em'}}><strong>Mardi - Jeudi - Vendredi</strong></li>
                  <li className="contact-infos-tabs">10h - 13h / 14h - 17h</li>
                  <li className="contact-infos-tabs" style={{fontSize: '1em'}}><strong>Mercredi</strong></li>
                  <li className="contact-infos-tabs">14h - 18h</li>
                  <li className="contact-infos-tabs" style={{fontSize: '1em'}}><strong>Samedi</strong></li>
                  <li className="contact-infos-tabs">10h - 13h / 14h - 17h</li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default IndexPage
